<template>
<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

        <!-- Login v1 -->
        <b-card class="mb-0">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                Account Verification
            </b-card-title>
            <b-card-text class="mb-2">
                Kindly enter verification code sent via SMS
            </b-card-text>
            <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="warning" class="mb-0" @dismiss-count-down="countDownChanged">
                <div class="alert-body">
                    <span>{{errorMessage}}</span>
                </div>
            </b-alert>

            <validation-observer ref="resetPassword">
                <b-form class="auth-reset-password-form mt-2" method="POST" @submit.prevent="validationForm">

                    <!-- password -->
                    <b-form-group label="Verification" label-for="reset-password-new">
                        <validation-provider #default="{ errors }" name="Password" vid="Password" rules="required">
                            <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                <b-form-input id="reset-password-new" v-model="verification" :type="passwordFieldType" :state="errors.length > 0 ? false:null" class="form-control-merge" name="reset-password-new" placeholder="············" />
                                <b-input-group-append is-text>
                                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="New Password" label-for="reset-password-new">
                        <validation-provider #default="{ errors }" name="Password" vid="Password" rules="required|password">
                            <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                <b-form-input id="reset-password-new" v-model="password" :type="passwordFieldType" :state="errors.length > 0 ? false:null" class="form-control-merge" name="reset-password-new" placeholder="············" />
                                <b-input-group-append is-text>
                                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- confirm password -->
                    <b-form-group label-for="reset-password-confirm" label="Confirm Password">
                        <validation-provider #default="{ errors }" name="Confirm Password" rules="required|confirmed:Password">
                            <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                <b-form-input id="reset-password-confirm" v-model="cPassword" :type="passwordFieldType" class="form-control-merge" :state="errors.length > 0 ? false:null" name="reset-password-confirm" placeholder="············" />
                                <b-input-group-append is-text>
                                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <!-- confirm password -->

                    <!-- submit button -->
                    <b-button block type="submit" variant="primary" @click.prevent="validationForm">
                        <b-spinner small v-show="isLoading" />
                        <span id="verify"> Verify Account </span>

                    </b-button>
                </b-form>
            </validation-observer>

            <p class="text-center mt-2" v-show="showResendActivation">
                <b-button @click="resendCode">
                    <feather-icon icon="ChevronLeftIcon" /> Resend Code
                </b-button>
            </p>
            <p class="text-center mt-2">
                <b-link :to="{name:'login'}">
                    <feather-icon icon="ChevronLeftIcon" /> Back to login
                </b-link>
            </p>
        </b-card>
        <!-- /Login v1 -->
    </div>
</div>
</template>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<script>
/* eslint-disable global-require */
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BCard,
    BImg,
    BForm,
    BButton,
    BAlert,
    BSpinner
} from 'bootstrap-vue'
import {
    required,
    email
} from '@validations'
import {
    togglePasswordVisibility
} from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    heightFade
} from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import $ from 'jquery'
export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BCard,
        BImg,
        BForm,
        BButton,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
        BAlert,
        BSpinner
    },
    directives: {
        heightFade,
        Ripple,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            verification: '',
            userEmail: '',
            sideImg: require('@/assets/images/pages/login-v2.svg'),
            // validation rulesimport store from '@/store/index'
            required,
            email,
            isLoading: false,
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            errorMessage: "",
            password: "",
            cPassword: "",
            showResendActivation: false
        }
    },
    mounted() {
        let vm = this;
        if (!vm.$cookies.get("username")) {
            vm.$router.push({
                name: 'login'
            })
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },

        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/authImage.png')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        resendCode() {
            let vm = this
            if (vm.$cookies.get("username")) {
                vm.isLoading = false
                $('#verify').html(' Please Wait ...');
                $.ajax({
                    url: store.state.rootUrl + 'account/v1/forget_password',
                    type: "post",
                    async: true,
                    crossDomain: true,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + store.state.accessToken
                    },
                    contentType: 'application/json',
                    processData: false,
                    dataType: 'json',
                    data: JSON.stringify({
                        user_name: vm.$cookies.get("username"),
                        country_code: vm.$cookies.get("country_code"),
                    }),
                    success: function (response, status, jQxhr) {
                        vm.isLoading = false
                        $('#verify').html(' Verify Account');
                        if (response.data.code != 200) {
                            vm.dismissCountDown = vm.dismissSecs
                            vm.showDismissibleAlert = true
                            vm.errorMessage = response.data.message
                            if (response.data.code == 121) {
                                vm.showResendActivation = true
                            }

                        } else {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })

                        }

                    },
                    error: function (jQxhr, status, error) {
                        vm.isLoading = false
                        vm.dismissCountDown = vm.dismissSecs
                        $('#verify').html(' Verify Account');
                        var errorMessage = jQxhr.responseJSON.statusDescription;
                        var statustext = jQxhr.responseJSON.message;
                        vm.showDismissibleAlert = true;
                        $('#login').text('login')
                        if (!statustext) {
                            vm.errorMessage = "An error occurred. Try again later.";
                        } else {
                            vm.errorMessage = statustext + " " + errorMessage;
                        }
                    }
                });
            } else {
                vm.showDismissibleAlert = true
                 vm.errorMessage = "You verification code has expired. Kindly click Resend Code "
                vm.showResendActivation = true
            }

        },
        validationForm() {
            let vm = this
            if (vm.$cookies.get("username")) {
            this.$refs.resetPassword.validate().then(success => {
                $.post({
                    url: store.state.rootUrl + 'account/v1/verify_account',
                    type: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + store.state.accessToken
                    },
                    contentType: 'application/json',
                    processData: false,
                    dataType: 'json',
                    data: JSON.stringify({
                        user_name: vm.$cookies.get("username"),
                        country_code: vm.$cookies.get("country_code"),
                        verification_code: vm.verification,
                        new_pass: btoa(vm.password)
                    }),
                    success: function (response, status, jQxhr) {
                        if (response.data.code != 200) {
                            vm.dismissCountDown = vm.dismissSecs
                            vm.showDismissibleAlert = true
                            vm.errorMessage = response.data.message
                            if (response.data.code == 121) {
                                vm.showResendActivation = true
                            }

                        } else {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            vm.$router.push({
                                name: 'login'
                            })
                        }

                    },
                    error: function (jQxhr, status, error) {
                        vm.isLoading = false
                        vm.dismissCountDown = vm.dismissSecs
                        $('#signIn').html('Sign In');
                        var errorMessage = jQxhr.responseJSON.errors[0];
                        var statustext = jQxhr.responseJSON.message;
                        vm.showDismissibleAlert = true;
                        $('#login').text('login')
                        if (!statustext) {
                            vm.errorMessage = "An error occurred. Try again later.";
                        } else {
                            vm.errorMessage = statustext + " " + errorMessage;
                        }
                    }
                });
            })
            } else {
                vm.showDismissibleAlert = true
                 vm.errorMessage = "You verification code has expired. Kindly click Resend Code "
                vm.showResendActivation = true
            }
        }

    }
}
</script>
